<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-center login-background"
    >
      <div class="d-flex flex-column align-items-sm-center mx-3 mx-sm-0">
        <div
          class="login-greeting ready-container d-flex flex-column justify-content-start flex-sm-row align-items-sm-center"
        >
          <img :src="ready" alt="Ready" class="ready-logo" />
          <div
            class="login-text-greeting ready-text d-flex flex-column justify-content-center"
          >
            <p class="mb-0">for Quantum Computing</p>
            <p class="mb-0">with QMware Cloud Services</p>
          </div>
        </div>
        <b-card border-variant="light" class="login-greeting login-card">
          <b-alert
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ dismissMessage }}
          </b-alert>

          <b-alert
            :show="showError"
            data-cy="login-error-alert"
            dismissible
            variant="danger"
          >
            {{ errorMessage }}
          </b-alert>

          <div v-if="loading">
            <div class="d-flex justify-content-between mb-3">
              <b-card-title class="mb-0">{{ $t("login.title") }}</b-card-title>
              <img :src="logo" alt="Qognite Logo" class="logo" />
            </div>

            <div
              v-if="loading"
              class="d-flex align-content-center justify-content-center py-4"
            >
              <div>
                <b-spinner class="mr-2" label="Small Spinner" small />
              </div>
              <div>
                <p v-if="!deviceRegister" class="mb-0">Logging in</p>
                <p v-else class="mb-0">Sending OTP</p>
              </div>
            </div>
          </div>

          <div v-else>
            <div v-if="!deviceRegister">
              <div class="d-flex justify-content-between mb-3">
                <b-card-title class="mb-0"
                  >{{ $t("login.title") }}
                </b-card-title>
                <img :src="logo" alt="Qognite Logo" class="logo" />
              </div>

              <div
                v-if="loading"
                class="d-flex align-content-center justify-content-center py-4"
              >
                <div>
                  <b-spinner class="mr-2" label="Small Spinner" small />
                </div>
                <div>
                  <p class="mb-0">Logging in</p>
                </div>
              </div>

              <ValidationObserver
                ref="observer"
                v-slot="{ handleSubmit, invalid }"
              >
                <b-form @submit.prevent="handleSubmit(login)">
                  <ValidationProvider name="Username or Email" rules="required">
                    <b-form-group
                      id="group-username"
                      :label="$t('login.usernameEmail')"
                      label-for="username"
                    >
                      <b-form-input
                        id="username"
                        v-model="userName"
                        :disabled="loading"
                        autofocus
                        data-cy="username"
                        size="lg"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider name="Password" rules="required">
                    <b-form-group
                      id="group-password"
                      :label="$t('login.password')"
                      data-cy="group-password"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :disabled="loading"
                        size="lg"
                        type="password"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <div class="d-flex mb-2 justify-content-center">
                    <b-link to="/reset-password">
                      {{ $t("login.forgotPassword") }}
                    </b-link>
                  </div>

                  <b-button
                    :disabled="invalid || loading"
                    class="gradient-button-outline w-100"
                    pill
                    type="submit"
                  >
                    {{ $t("login.loginButton") }}
                  </b-button>
                </b-form>

                <div v-if="enableTq42Login === 'true'">
                  <div class="divider mt-4 mb-2" />

                  <div class="d-flex justify-content-center">
                    <p>OR</p>
                  </div>

                  <b-button
                    :disabled="loading"
                    :href="auth0LoginUrl"
                    class="w-100 login-tq42"
                    variant="outline-dark"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center my-1"
                    >
                      <div class="login-tq42-text mr-1">Sign in with</div>
                      <div class="login">
                        <img
                          :src="tq42_logo"
                          alt="TQ42 Logo"
                          class="tq42_logo"
                        />
                      </div>
                    </div>
                  </b-button>
                  <div class="d-flex justify-content-end small">
                    <p class="mt-2 mb-0">
                      read more about
                      <a href="https://tq42.com" target="_blank">TQ42</a>
                    </p>
                  </div>
                </div>
              </ValidationObserver>
            </div>
            <OTPInput
              v-else
              ref="otpInput"
              :email="forDeviceToken.email"
              :loading="loading"
              :num-inputs="6"
              @get-otp="loginOTP"
              @resend-otp="resendOTP()"
              @go-back="goBack()"
            />
          </div>
        </b-card>
        <div v-if="enableOCI === 'true'" class="d-flex justify-content-center">
          <p class="login-text-greeting">powered by Oracle OCI</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import cfg from "../config";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { hash_password } from "@/util/hashing";
import qognite_logo from "@/assets/images/qognite-logo.svg";
import ready from "@/assets/images/ready.svg";
import tq42_logo from "@/assets/images/tq42_logo.svg";
import axios from "axios";
import OTPInput from "@/components/OTPInput";
import Footer from "@/components/Footer";
import { v4 as uuidv4 } from "uuid";

extend("required", {
  ...required,
  message: "{_field_} is required!",
});

export default {
  components: {
    OTPInput,
    ValidationProvider,
    ValidationObserver,
    Footer,
  },
  name: "Login",
  data() {
    return {
      userName: "",
      password: "",
      deviceRegister: false,
      showError: false,
      errorMessage: "",
      logo: qognite_logo,
      ready: ready,
      tq42_logo: tq42_logo,
      showSuccess: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      dismissMessage: "",
      loading: false,
      forDeviceToken: {
        userId: "",
        userName: "",
        email: "",
        oldToken: "",
      },
      auth0LoginUrl: process.env.VUE_APP_AUTH0_LOGIN_URL,
      enableTq42Login: process.env.VUE_APP_ENABLE_TQ42_LOGIN,
      enableOCI: process.env.VUE_APP_ENABLE_OCI,
    };
  },
  async mounted() {
    // login if Auth0 code is present
    if (this.$route.query.code) {
      this.loading = true;

      try {
        const res = await axios.put(
          cfg.BASE_URL + cfg.BASE_IDENTITY_VERSION_URL + "/login-auth0",
          {
            authCode: this.$route.query.code,
            deviceToken: "test",
          }
        );

        if (res.status === 204) {
          await this.$router.replace("/login");
          this.dismissMessage =
            "Account Link Confirmation Email sent! \n Confirm the account link and then login into the platform!";
          this.dismissCountDown = 30;
        } else {
          await this.$store.dispatch("login", res.data);
          await this.$router.push("/qres");
        }
      } catch (err) {
        if (err.response.status === 401) {
          this.showError = true;
          this.errorMessage = err.response.data.message;
          await this.$router.replace("/login");
        } else {
          this.showError = true;
          this.errorMessage = "Something went wrong!";
          await this.$router.replace("/login");
        }
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    getBrowser() {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "MS Edge";
        case agent.indexOf("edg/") > -1:
          return "Edge ( chromium based)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "Chrome";
        case agent.indexOf("trident") > -1:
          return "MS IE";
        case agent.indexOf("firefox") > -1:
          return "Mozilla Firefox";
        case agent.indexOf("safari") > -1:
          return "Safari";
        default:
          return "other";
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },

    async resendOTP() {
      await this.login();
      if (!this.showError) {
        this.dismissCountDown = this.dismissSecs;
        this.dismissMessage = "Code was resent!";
      }
    },

    goBack() {
      this.userName = "";
      this.password = "";
      this.deviceRegister = false;
    },

    async loginNavigate() {
      if (this.$route.query.ref) {
        let target = decodeURIComponent(this.$route.query.ref);
        let resolved = this.$router.resolve(target);
        if (resolved.route.name === "CatchAll") {
          // use window.location.origin to prevent redirecting to some other domain
          window.location.replace(window.location.origin + target);
        } else {
          await this.$router.replace(target);
        }
      } else {
        await this.$router.push("/qres");
      }
    },

    async loginOTP(otp) {
      this.loading = true;
      try {
        const { data } = await axios.post(
          cfg.BASE_URL + cfg.BASE_2FA_VERSION_URL + "/create-token",
          {
            otp: otp,
            userId: this.forDeviceToken.userId,
            browser: this.getBrowser(),
            location: "Timisoara",
          }
        );
        // get token map from localStorage
        const tokenMap = JSON.parse(localStorage.getItem("TOKEN_MAP") ?? "[]");
        const tokenId = uuidv4();

        // push new user map for token
        tokenMap.push({
          id: tokenId,
          userName: this.forDeviceToken.userName,
          email: this.forDeviceToken.email,
        });

        // store new token map and the new device token
        localStorage.setItem("TOKEN_MAP", JSON.stringify(tokenMap));
        localStorage.setItem(`DEVICE_TOKEN_${tokenId}`, data.deviceToken);

        await this.login();
      } catch (err) {
        this.handleClearInput();
        if (err.response.status === 401) {
          this.showError = true;
          this.errorMessage = err.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },

    async login() {
      this.showError = false;
      this.loading = true;

      // find token for user based on username/email
      const tokenMap = JSON.parse(localStorage.getItem("TOKEN_MAP") ?? "[]");
      const tokenId = tokenMap.find(
        (el) =>
          el.userName === this.fullUserName() ||
          el.email === this.fullUserName()
      )?.id;
      const deviceToken = tokenId
        ? localStorage.getItem(`DEVICE_TOKEN_${tokenId}`)
        : "";
      const hashedPassword = await hash_password(this.password);

      try {
        // attempt to login
        const { data } = await axios.put(
          cfg.BASE_URL + cfg.BASE_IDENTITY_VERSION_URL + "/login",
          {
            name: this.userName,
            password: hashedPassword,
            ...(deviceToken && { deviceToken: deviceToken }),
          }
        );
        // if refreshToken is missing do 2FA challenge
        if (data.refreshToken) {
          await this.$store.dispatch("login", data);
          await this.loginNavigate();

          // check user roles
          if (data.roles.length < 1) {
            this.showToast();
          }
        } else {
          await axios.post(
            cfg.BASE_URL + cfg.BASE_2FA_VERSION_URL + "/request-token",
            {
              token: data.token,
            }
          );
          // remove old token
          if (deviceToken) {
            localStorage.removeItem(`DEVICE_TOKEN_${tokenId}`);
            const newTokenMap = tokenMap.filter((el) => el.id !== tokenId);
            localStorage.setItem("TOKEN_MAP", JSON.stringify(newTokenMap));
          }

          // set data for otp form
          this.email = data.email;
          this.forDeviceToken.userId = data.userId;
          this.forDeviceToken.userName = data.name;
          this.forDeviceToken.email = data.email;

          this.deviceRegister = true;
        }
      } catch (error) {
        this.showError = true;
        this.errorMessage = "Invalid Credentials! Please try again.";
        this.userName = "";
        this.password = "";
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      } finally {
        this.loading = false;
      }
    },

    fullUserName() {
      if (this.userName.includes("@") || this.userName.includes("/")) {
        return this.userName.toLowerCase();
      } else {
        return `${this.userName.toLowerCase()}/${this.userName.toLowerCase()}`;
      }
    },

    showToast() {
      const toast = this.$createElement;

      const vNodesMsg = toast("p", { class: ["text-left", "mb-0"] }, [
        toast("b-icon-exclamation-circle-fill", {
          props: {
            animation: "fade",
            fontScale: 1,
          },
        }),
        toast("strong"),
        ` You have no roles assigned. Please contact your administrator. `,
      ]);

      this.$bvToast.toast([vNodesMsg], {
        title: "",
        solid: true,
        variant: "danger",
        toaster: "b-toaster-bottom-right",
        noAutoHide: true,
      });
    },
  },
};
</script>

<style scoped>
.login-greeting {
  margin-bottom: 20px;
}

.ready-logo {
  height: 76px;
  width: 151px;
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .ready-logo {
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

@media (min-width: 880px) {
  .ready-logo {
    width: 255px;
    height: 128px;
    margin-right: 30px;
  }

  .ready-container {
    margin-bottom: 30px;
  }

  .ready-text {
    font-size: 30px;
    line-height: 34px;
  }
}

.tq42_logo {
  height: 25px;
}
</style>
