<template>
  <div class="otp-width">
    <b-card-title class="text-center mb-4">
      {{ $t("otp.title") }}
    </b-card-title>
    <b-card-text class="text-center mb-0">
      {{ $t("otp.codeSentTo") }}
    </b-card-text>
    <b-card-text class="text-center mb-5">
      <b>{{ email }}</b>
    </b-card-text>

    <b-alert
      v-if="showError"
      data-cy="login-error-alert"
      dismissible
      show
      variant="danger mt-1"
    >
      {{ $t("otp.invalidCredentials") }}
    </b-alert>

    <b-form @submit.prevent="submitOTP">
      <div class="d-flex mb-5 p-1">
        <SingleOtpInput
          v-for="(item, i) in numInputs"
          :key="i"
          :focus="activeInput === i"
          :loading="loading"
          :should-auto-focus="shouldAutoFocus"
          :value="otp[i]"
          @on-change="handleOnChange"
          @on-keydown="handleOnKeyDown"
          @on-paste="handleOnPaste"
          @on-focus="handleOnFocus(i)"
          @on-blur="handleOnBlur"
        />
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="d-flex flex-column flex-sm-row align-items-center mb-4">
          <p class="mr-2 mb-0">{{ $t("otp.notReceived") }}</p>
          <p class="resend-otp text-primary mb-0" @click="resendOTP()">
            {{ $t("otp.resend") }}
          </p>
        </div>
        <b-button
          :disabled="this.otp.join('').length !== this.numInputs || loading"
          class="gradient-button-outline w-100 mb-5"
          pill
          type="submit"
        >
          <b-spinner v-show="loading" class="mr-1" small type="grow" />

          {{ $t("otp.verify") }}
        </b-button>
        <p class="back-otp text-primary" @click="back()">{{ $t("back") }}</p>
      </div>
    </b-form>
  </div>
</template>

<script>
import SingleOtpInput from "./SingleOTPInput";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
  name: "OTPInput",
  components: { SingleOtpInput },
  data() {
    return {
      activeInput: 0,
      otp: [],

      // legacy
      showError: false,
    };
  },
  props: {
    numInputs: {
      default: 4,
    },
    shouldAutoFocus: {
      type: Boolean,
      default: true,
    },
    email: {
      default: "",
    },
    method: {
      type: Function,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    back() {
      this.$emit("go-back");
    },
    resendOTP() {
      this.$emit("resend-otp");
    },
    submitOTP() {
      const token = this.otp.join("");
      this.$emit("get-otp", token);
    },
    handleOnFocus(index) {
      this.activeInput = index;
    },
    handleOnBlur() {
      this.activeInput = -1;
    },
    // Focus on input by index
    focusInput(input) {
      this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0);
    },
    // Focus on next input
    focusNextInput() {
      this.focusInput(this.activeInput + 1);
    },
    // Focus on previous input
    focusPrevInput() {
      this.focusInput(this.activeInput - 1);
    },
    // Change OTP value at focused input
    changeCodeAtFocus(value) {
      this.oldOtp = [...this.otp];
      this.$set(this.otp, this.activeInput, value);
      if (this.oldOtp.join("") !== this.otp.join("")) {
        this.$emit("on-change", this.otp.join(""));
      }
    },
    // Handle pasted OTP
    handleOnPaste(event) {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData("text/plain")
        .slice(0, this.numInputs - this.activeInput)
        .split("");
      if (this.isInputNum && !pastedData.join("").match(/^\d+$/)) {
        return "Invalid pasted data";
      }
      // Paste data from focused input onwards
      const currentCharsInOtp = this.otp.slice(0, this.activeInput);
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
      this.$set(this, "otp", combinedWithPastedData.slice(0, this.numInputs));
      this.focusInput(combinedWithPastedData.slice(0, this.numInputs).length);
    },
    handleOnChange(value) {
      this.changeCodeAtFocus(value);
      this.focusNextInput();
    },
    clearInput() {
      if (this.otp.length > 0) {
        this.$emit("on-change", "");
      }
      this.otp = [];
      this.activeInput = 0;
    },
    // Handle cases of backspace, delete, left arrow, right arrow
    handleOnKeyDown(event) {
      switch (event.keyCode) {
        case BACKSPACE:
          event.preventDefault();
          this.changeCodeAtFocus("");
          this.focusPrevInput();
          break;
        case DELETE:
          event.preventDefault();
          this.changeCodeAtFocus("");
          break;
        case LEFT_ARROW:
          event.preventDefault();
          this.focusPrevInput();
          break;
        case RIGHT_ARROW:
          event.preventDefault();
          this.focusNextInput();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.resend-otp {
  font-weight: bold;
  text-decoration: none !important;
  cursor: pointer;
  user-select: none;
}

.back-otp {
  text-decoration: none !important;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
}

.resend-otp:hover {
  color: #6169d0;
}

.otp-width {
  max-width: 400px;
}
</style>
