<template>
  <div style="display: flex; align-items: center">
    <b-form-input
      class="mx-1 m-sm-2 text-center"
      min="0"
      max="9"
      ref="input"
      v-model="model"
      autocomplete="off"
      :disabled="loading"
      :formatter="formatter"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
  </div>
</template>

<script>
export default {
  name: "SingleOtpInput",
  props: {
    value: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value || "",
    };
  },
  mounted() {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus();
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue;
      }
    },
    // whenever question changes, this function will run
    focus(newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      // Prevent calling function if input already in focus
      if (oldFocusValue !== newFocusValue && this.$refs.input && this.focus) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    },
  },
  methods: {
    formatter(e) {
      return String(e).substring(0, 1);
    },
    handleOnChange() {
      return this.$emit("on-change", this.model);
    },
    handleOnKeyDown(event) {
      // Only allow characters alphanumeric, DEL, Backspace, Enter, Right and Left Arrows, and Pasting
      const keyEvent = event || window.event;
      const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
      if (
        this.isCodeNumeric(charCode) ||
        [8, 9, 13, 37, 39, 46, 86].includes(charCode)
      ) {
        this.$emit("on-keydown", event);
      } else {
        keyEvent.preventDefault();
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return charCode >= 32 && charCode <= 127;
    },
    handleOnPaste(event) {
      return this.$emit("on-paste", event);
    },
    handleOnFocus() {
      this.$refs.input.select();
      return this.$emit("on-focus");
    },
    handleOnBlur() {
      return this.$emit("on-blur");
    },
  },
};
</script>
